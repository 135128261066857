<template>
  <div class="container">
    <div class="box">
      <div class="a">
        <img src="@/assets/a.png" alt="" />
      </div>
      <div class="b">
        <div class="b_a">倒计时：</div>
        <van-count-down :time="time">
          <template #default="timeData">
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds }}</span>
          </template>
        </van-count-down>
      </div>
      <div class="c">
        <div class="c_box">
          <div class="c_l">
            <div class="c_l_a">秒杀券</div>
          </div>
          <div class="c_r">
            <div class="c_r_a">
              <span>￥</span>
              <div class="c_r_text">
                {{ formData.price ? formData.price :0 }}
                <div class="c_r_img">
                  <div>5折</div>
                </div>
              </div>
            </div>
            <div class="c_r_b">满{{ formData.limitPrice ? formData.limitPrice :0 }}元使用</div>
          </div>
        </div>
        <img src="@/assets/c.png" alt="" class="c_z" />
        <img src="@/assets/c2.png" alt="" class="c_y" />
        <img src="@/assets/c3.png" alt="" class="c_x" />
        <img src="@/assets/c4.png" alt="" class="c_w" />
      </div>
      <div class="d" v-if="status == 0">
        剩余:
        <span
          >{{ formData.dayMargin ?formData.dayMargin:0 }}/{{
            formData.dayNum ? formData.dayNum : 0
          }}张</span
        >
      </div>
      <div class="d" v-if="status == 1">秒杀券发送成功</div>
      <div class="e" @click="receive" v-if="status == 0">立即抢券</div>
      <div class="e" @click="goUse" v-if="status == 1">立即使用</div>
      <div class="f">每周仅可领取一次</div>
    </div>
    <div class="g">
      <van-checkbox v-model="checked" icon-size="12px">
        <template #icon="props">
          <div :class="{ g_icon: true, g_icon_active: props.checked }"></div>
        </template>
      </van-checkbox>
      <div class="g_a" @click="openPrivacy">
        <div>隐私协议</div>
        <div>/</div>
        <div>服务协议</div>
      </div>
    </div>
    <img src="@/assets/e.png" alt="" class="container_a_back" />
    <img src="@/assets/f.png" alt="" class="container_b_back" />

    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="p" @click.stop>
          <div class="p_a">请输入手机号</div>
          <input
            type="number"
            v-model="phone"
            placeholder="请输入领券手机号"
            class="p_input"
          />
          <div class="p_c" @click.stop="surePhone">确定</div>
        </div>
      </div>
    </van-overlay>

    <van-popup v-model:show="privacyShow" position="bottom" round>
       <template #default>
        <div class="pri">
          <div v-html="privacyHtml"></div>
        </div>
       </template>
    </van-popup>

  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { showToast, showSuccessToast,showDialog,showFailToast, Toast } from "vant";
import API from "@/config/api";

import privacyHtml from '@/utils/privacy.js'

import { useRoute } from "vue-router";

export default {
  setup() {
    let time = ref("0");
    let endTime =
      new Date(new Date().toLocaleDateString()).getTime() + 86400000;
    let now = new Date().getTime();
    time.value = Math.abs(endTime - now);

    let checked = ref(false);
    let shopId = "";
    let show = ref(false);
    let phone = ref("");
    let formData = reactive({});
    let status = ref(0)
    let privacyShow = ref(false)
    onMounted(() => {
      getActivity();
    });
    const route = useRoute();
    shopId = route.query.shopId;
    function getActivity() {
      API.getActivity(shopId).then((res) => {
        if (res.code == 200) {
          formData = Object.assign(formData, res.data);
        } else {
          showToast(res.msg);
        }
      });
    }
    function receive() {
      if (!checked.value) {
        showDialog({
          title: "温馨提示",
          message: "请先阅读并同意勾选隐私协议和服务协议",
          showCancelButton: true,
          confirmButtonColor: "#F72C5C",
        })
          .then(() => {
            checked.value = true;
          })
          .catch(() => {});
        return;
      }
      show.value = true;
    }
    function surePhone() {
      if (phone.value == "") {
        return showToast("请输入手机号");
      }
      const reg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      if (!reg.test(phone.value)) {
        return showToast('请输入正确的手机号')
      }
      let params = {
        phone: phone.value,
        shopId: shopId,
        activeId: formData.id,
      };
      API.receive(params).then((res) => {
        show.value = false;
        if(formData.dayMargin == 0){
          showToast("今日券已被抢光，明天早点来哦！");
        }else{
          showToast(res.msg);
          setTimeout(()=>{
          status.value = 1;
        },2000)
        }
       
        // if (res.code == 200) {
        //   showSuccessToast("领券成功");
        //   setTimeout(()=>{
        //     getActivity()
        //   },2000)
        // }else{
        //   showToast(res.msg)
        // }
      });
    }
    function goUse(){
      API.getUrl(shopId).then(res=>{
          if(res.code == 200){
            window.location.href=res.msg
          }else{
            showToast(res.msg)
          }
      })
    }
    function openPrivacy(){
      privacyShow.value = true;
    }
    return {
      time,
      checked,
      receive,
      show,
      phone,
      surePhone,
      formData,
      status,
      privacyShow,
      privacyHtml,
      openPrivacy,
      goUse
    };
  },
};
</script>
<style scoped lang="scss">
.pri{
  height: 60vh;
  overflow-y: scroll;
  padding: 12px 14px;
  div,p{
    line-height: 30px;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .p {
    width: 80vw;
    background-color: #fff;
    border-radius: 16px;
    padding: 18px 12px;
    .p_a {
      font-size: 16px;
      font-weight: bold;
      color: #222222;
      text-align: center;
    }
    .p_input {
      margin-top: 18px;
      height: 46px;
      background: #f5f6fa;
      border-radius: 6px;
      border: none;
      width: 100%;
      padding: 0 16px;

      &::-webkit-input-placeholder {
        /* placeholder颜色 */
        color: #aab2bd;
        /* placeholder字体大小 */
        font-size: 14px;
      }
    }
    .p_c {
      width: 100%;
      height: 40px;
      background: linear-gradient(to bottom, #ffe900, #ff6501);
      border-radius: 30px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 18px;
    }
  }
}

.container {
  height: 100%;
  width: 100%;
  // background: #f40f45;
  background: url("@/assets/back.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 10;
}
.container_a_back {
  position: absolute;
  z-index: -1;
  top: 40%;
  transform: translateY(-50%);
  width: 100%;
}
.container_b_back {
  position: absolute;
  z-index: -1;
  top: 0%;
  // transform: translateY(-50%);
  width: 100%;
}
.g {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
  .g_icon {
    border: 2px solid #ffffff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .g_icon_active {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      background: #fff;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 80%;
      border-radius: 50%;
    }
  }
  .g_a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    margin-left: 6px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
  }
}
.f {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  margin-top: 6px;
}
.e {
  width: 180px;
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.7);
  background: linear-gradient(to bottom, #ffe900, #ff6501);
  border-radius: 30px;
  letter-spacing: 2px;
  text-align: center;
  padding: 6px 0;
  margin-top: 20px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.3);
}
.d {
  font-size: 25px;
  color: #fff;
  letter-spacing: 1px;
}
.box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .a {
    // width: 100%;
    img {
      width: 64%;
      display: block;
      margin: 0 auto;
    }
    // font-size: 32px;
    // font-weight: bold;
    // color: #fff;
    // letter-spacing: 1px;
  }
  .b {
    margin-top: 20px;
    color: #fff;
    font-size: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    .b_a {
      margin-top: -6px;
      letter-spacing: 1px;
    }
    span {
      font-size: 25px;
      color: #fff;
    }
  }
}
.c {
  position: relative;
  margin-top: 20px;
  width: 290px;
  height: 145px;
  // width: 75vw;
  // height: 37vw;
  background: url("@/assets/a_back.png") no-repeat;
  background-size: 100% 100%;
  .c_box {
    position: absolute;
    width: 84%;
    height: 75%;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
    display: flex;

    .c_l {
      width: 22%;
      height: 100%;
      position: relative;
      .c_l_a {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #df0d3d;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.1;
      }
    }
    .c_r {
      width: 78%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .c_r_a {
        color: #af0027;
        display: flex;
        align-items: baseline;
        font-weight: bold;
        margin-top: 6px;
        span {
          font-size: 24px;
        }
        .c_r_text {
          font-size: 50px;
          margin-left: -6px;
          position: relative;
        }
        .c_r_img {
          position: absolute;
          right: -30px;
          top: 7%;
          background: url("@/assets/b.png") no-repeat;
          background-size: 100% 100%;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 27px;
          animation: shake 2s linear;
          animation-iteration-count: infinite;
          transform-origin: left bottom;
          div {
            font-size: 12px;
            color: #f49a0c;
            transform: rotate(30deg);
          }
        }
        @keyframes shake {
          0% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(15deg);
          }
          50% {
            transform: rotate(30deg);
          }
          75% {
            transform: rotate(15deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      }
      .c_r_b {
        color: #df0d3d;
        font-size: 18px;
        margin-top: -6px;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  }
}
.c_z {
  position: absolute;
  right: 0;
  bottom: 42px;
  width: 38px;
  height: 38px;
}
.c_y {
  position: absolute;
  width: 42px;
  height: 42px;
  right: -28px;
  top: -20px;
}
.c_x {
  position: absolute;
  width: 42px;
  height: 42px;
  left: -12px;
  bottom: -18px;
}
.c_w {
  position: absolute;
  width: 28px;
  height: 28px;
  top: -14px;
  left: -14px;
}
</style>