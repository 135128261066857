
import axios from 'axios';
import {showToast} from 'vant'
import { load } from '@/utils/loading.js';

const myRequest = axios.create({
    // baseURL: '/api', // 替换为你的后端接口地址
    baseURL: '/api', // 替换为你的后端接口地址
    timeout: 10000, // 请求超时时间
});

load.show('加载中...');

myRequest.interceptors.request.use(
    config => {
        // 在请求发送之前可以做一些处理，比如添加请求头等
        // config.headers.Authorization =  localStorage.getItem('token') ?  "Bearer " + localStorage.getItem('token') : null;
        return config;
    },
    error => {
        // 请求错误处理
        load.hide();
        return Promise.reject(error);
        
    }
);

// 响应拦截器
myRequest.interceptors.response.use(
    response => {
        // 在这里可以对响应数据进行处理
        load.hide();
        return response.data;
    },
    error => {
        // 响应错误处理
        load.hide();
        showToast('网络连接失败')
        
        return Promise.reject(error);
    }
);
export default myRequest