let privacy = `
<h3>重要须知:</h3>
<div>外卖大师在此特别提醒用户认真阅读、充分理解本《服务协议》（下称《协议》）--- 用户应认真阅读、充分理解本《协议》中各条款，包括免除或者限制外卖大师责任的免责条款及对用户的权利限制条款。请您审慎阅读并选择接受或不接受本《协议》（未成年人应在法定监护人陪同下阅读）。除非您接受本《协议》所有条款，否则您无权注册、登录或使用本协议所涉相关服务。您的注册、登录、使用等行为将视为对本《协议》的接受，并同意接受本《协议》各项条款的约束。

本《协议》是您（下称“用户”）与外卖大师公司（下称“外卖大师”）之间关于用户注册、登录、使用“外卖大师”服务所订立的协议。本《协议》描述外卖大师与用户之间关于“外卖大师”服务相关方面的权利义务。“用户”是指注册、登录、使用、浏览本服务的个人或组织。

本协议可视为《外卖大师软件许可及服务协议》及《外卖大师网站服务条款》的补充条款，与其构成统一整体，您对本协议的接受即受全部条款的约束，包括接受外卖大师公司对任一服务条款随时所做的任何修改。本《协议》可由外卖大师随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本网站查阅最新版协议条款。在外卖大师修改《协议》条款后，如果用户不接受修改后的条款，请立即停止使用外卖大师提供的服务，用户继续使用外卖大师提供的服务将被视为已接受了修改后的协议。
</div>

<h3>一、使用规则</h3>

<div>1、用户充分了解并同意，外卖大师仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册帐号下的一切行为负责，包括您所传送的任何内容以及由此产生的任何结果。用户应对外卖大师中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。外卖大师公司无法且不会对因用户行为而导致的任何损失或损害承担责任。</div>

<div>2、用户在外卖大师服务中或通过外卖大师微信服务所传送的任何内容并不反映外卖大师公司的观点或政策，外卖大师公司对此不承担任何责任。</div>

<div>3、用户充分了解并同意，外卖大师是一个基于用户关系网的点对点即时通讯产品，用户须对在外卖大师上的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义传播任何信息；不得恶意使用注册帐号导致其他用户误认；否则外卖大师公司有权立即停止提供服务，收回外卖大师帐号并由用户独自承担由此而产生的一切法律责任。</div>

<div>4、用户须对在外卖大师上所传送信息的真实性、合法性、无害性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与外卖大师无关。</div>

<div>5、外卖大师保留因业务发展需要，单方面对本服务的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销外卖大师服务的权利，用户需承担此风险。</div>

<div>6、外卖大师提供的服务中可能包括广告，用户同意在使用过程中显示外卖大师和第三方供应商、合作伙伴提供的广告。</div>

<div>7、用户不得利用外卖大师或外卖大师服务制作、上载、复制、发送如下内容：</div>

<p>(1) 反对宪法所确定的基本原则的；</p>

<p>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>

<p>(3) 损害国家荣誉和利益的；</p>

<p>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</p>

<p>(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>

<p>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>

<p>(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>

<p>(8) 侮辱或者诽谤他人，侵害他人合法权益的；</p>

<p>(9) 含有法律、行政法规禁止的其他内容的信息。</p>

<div>8、外卖大师公司可依其合理判断，对违反有关法律法规或本协议约定；或侵犯、妨害、威胁任何人权利或安全的内容，或者假冒他人的行为，外卖大师公司有权依法停止传输任何前述内容，并有权依其自行判断对违反本条款的任何人士采取适当的法律行动，包括但不限于，从外卖大师服务中删除具有违法性、侵权性、不当性等内容，终止违反者的成员资格，阻止其使用外卖大师全部或部分服务，并且依据法律法规保存有关信息并向有关部门报告等。</div>

<div>9、用户权利及义务：</div>

<p>(1) 外卖大师帐号的所有权归外卖大师公司所有，用户完成申请注册手续后，获得外卖大师帐号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。外卖大师公司因经营需要，有权回收用户的外卖大师帐号。</p>

<p>(2) 用户有权更改、删除在外卖大师上的个人资料、注册信息及传送内容等，但需注意，删除有关信息的同时也会删除任何您储存在系统中的文字和图片。用户需承担该风险。</p>

<p>(3) 用户有责任妥善保管注册帐号信息及帐号密码的安全，用户需要对注册帐号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他成员的帐号或密码。在您怀疑他人在使用您的帐号或密码时，您同意立即通知外卖大师公司。</p>

<p>(4) 用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，外卖大师公司有权依据协议终止对违约用户外卖大师帐号提供服务。同时，外卖大师保留在任何时候收回外卖大师帐号、用户名的权利。</p>

<p>(5) 如果用户有自己的常用英文帐号，那么用户有优先将该英文帐号注册为外卖大师帐号的权利，但是如果用户在外卖大师服务上线后一段时间内没有注册外卖大师帐号，或者用户虽然注册了外卖大师帐号，但是并不是使用该英文帐号作为外卖大师帐号的，视为用户放弃了将该英文帐号注册为外卖大师帐号的权利，外卖大师公司有权将该英文帐号回收并分配给其他用户使用，以免造成资源浪费，由此带来问题均由用户自行承担。</p>

<p>(6) 用户注册外卖大师帐号后如果长期不登录该帐号，外卖大师有权回收该帐号，以免造成资源浪费，由此带来问题均由用户自行承担。</p>

<h3>二、隐私保护</h3>

<div>用户同意个人隐私信息是指那些能够对用户进行个人辨识或涉及个人通信的信息，包括下列信息：用户真实姓名，身份证号，手机号码，IP地址。而非个人隐私信息是指用户对本服务的操作状态以及使用习惯等一些明确且客观反映在外卖大师服务器端的基本记录信息和其他一切个人隐私信息范围外的普通信息；以及用户同意公开的上述隐私信息；

尊重用户个人隐私信息的私有性是外卖大师的一贯制度，外卖大师将会采取合理的措施保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或用户同意等原因外，外卖大师未经用户同意不向除合作单位以外的第三方公开、 透露用户个人隐私信息。 但是，用户在注册时选择同意，或用户与外卖大师及合作单位之间就用户个人隐私信息公开或使用另有约定的除外，同时用户应自行承担因此可能产生的任何风险，外卖大师对此不予负责。同时，为了运营和改善外卖大师的技术和服务，外卖大师将可能会自行收集使用或向第三方提供用户的非个人隐私信息，这将有助于外卖大师向用户提供更好的用户体验和提高外卖大师的服务质量。

用户同意，在使用外卖大师服务时也同样受外卖大师隐私政策的约束。当您接受本协议条款时，您同样认可并接受外卖大师公司隐私政策的条款。</div>

<h3>三、外卖大师商标信息</h3>

<div>外卖大师服务中所涉及的外卖大师logo等图形、文字或其组成，以及其他外卖大师标志及产品、服务名称，均为外卖大师公司之商标（以下简称“外卖大师标识”）。未经外卖大师事先书面同意，用户不得将外卖大师标识以任何方式展示或使用或作其他处理，也不得向他人表明您有权展示、使用、或其他有权处理外卖大师标识的行为。</div>

<h3>四、法律责任及免责</h3>

<div>1、用户违反本《协议》或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿外卖大师与合作公司、关联公司，并使之免受损害。</div>

<div>2、用户因第三方如电信部门的通讯线路故障、技术问题、网络、电脑故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，外卖大师及合作单位不承担责任。</div>

<div>3、因技术故障等不可抗事件影响到服务的正常运行的，外卖大师及合作单位承诺在第一时间内与相关单位配合，及时处理进行修复，但用户因此而遭受的一切损失，外卖大师及合作单位不承担责任。</div>

<div>4、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</div>

<div>5、用户须明白，使用本服务因涉及Internet服务，可能会受到各个环节不稳定因素的影响。因此，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。用户须承担以上风险，外卖大师公司不作担保。对因此导致用户不能发送和接受阅读信息、或接发错信息，外卖大师公司不承担任何责任。</div>

<div>6、用户须明白，在使用本服务过程中存在有来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）的匿名或冒名的信息的风险，用户须承担以上风险，外卖大师公司和合作公司对本服务不作任何类型的担保，不论是明确的或隐含的，包括所有有关信息真实性、适商性、适于某一特定用途、所有权和非侵权性的默示担保和条件，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、特殊及后续的损害，不承担任何责任。</div>

<div>7、外卖大师公司定义的信息内容包括：文字、软件、声音、相片、录像、图表；在广告中全部内容；外卖大师公司为用户提供的商业信息，所有这些内容受版权、商标权、和其它知识产权和所有权法律的保护。所以，用户只能在外卖大师公司和广告商授权下才能使用这些内容，而不能擅自复制、修改、编纂这些内容、或创造与内容有关的衍生产品。</div>

<div>8、在任何情况下，外卖大师公司均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用外卖大师服务而遭受的利润损失，承担责任（即使外卖大师已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，外卖大师公司对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您在成员期内因使用外卖大师服务而支付给外卖大师公司的费用(如有) 。</div>

<h3>五、其他条款</h3>

<div>1、外卖大师公司郑重提醒用户注意本《协议》中免除外卖大师公司责任和加重用户义务的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本《协议》。以上各项条款内容的最终解释权及修改权归外卖大师公司所有。</div>

<div>2、本《协议》所定的任何条款的部分或全部无效者，不影响其它条款的效力。</div>

<div>3、本《协议》签订地为西安。本《协议》的解释、效力及纠纷的解决，适用于中华人民共和国法律。若用户和外卖大师之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户在此完全同意将纠纷或争议提交外卖大师住所地地即西安市有管辖权的人民法院管辖。</div>

<div>4、本《协议》的版权由外卖大师所有，外卖大师保留一切解释和修改权利。</div>

<div>5、若您对外卖大师公司及本服务有任何意见，欢迎垂询外卖大师公司客服中心。外卖大师客户服务热线：17386974087。</div>
` 
export default privacy