import myRequest from './request'

export default {
	getActivity(shopId){
		return myRequest.post(`/tool/imCoupon/h5/${shopId}`)
	},
	receive(params){
		return myRequest.post('/tool/imCoupon/h5/receive',params)
	},
	getUrl(shopId){
		return myRequest.post(`/tool/imCoupon/h5/getUrl/${shopId}`)
	}
}